import React from 'react';

const FOOTNOTE = (
    <>
        RBN rewards its eligible Members for residential home sales or purchases. These rewards are offered in collaboration with licensed real estate Brokers and Participating Loyalty Providers. All real estate brokerage components of this program are managed and offered directly by these participating real estate Brokerages in cooperation with RBN Realty, an Arizona Brokerage owned by RBN LLC. The cost to provide rewards is collected from participating licensed real estate Brokers. Real estate broker fees may apply. RBN Rewards are based on the participating agent's commission rate associated with the sold or purchased home. Real estate commission rates are negotiable and may vary, therefore point value may also vary.  Market availability, participating partners and available rewards are subject to change without notice. RBN Rewards are not available in all markets or where prohibited by law and may be subject to lender approval.  Rewards are currently available as American Express Membership Rewards&reg; Points on eligible American Express Cards.  Customers must connect and partner with an RBN participating agent through the RBN platform to be eligible to earn rewards.  RBN respects existing contractual obligations with agents and their clients. Customers with existing agent agreements are not eligible to earn RBN Rewards.
    </>
);

const EXPANSION_FOOTNOTE = (
    <>
        RBN is expanding our network of loyalty program partners to some of the biggest luxury and travel brands in the world. In order to earn American Express Membership Rewards points with an RBN participating agent on your home sale or purchase, you must connect your eligible American Express Membership Rewards program account by June 30th, 2023, and close on your home sale or purchase by July 12th, 2024.  If your home sale or purchase closes after July 12th, 2024, you will be qualified for rewards with our newly expanded loyalty program partners.
    </>
)


export const rbn = {
    footnote: {
        symbol: '*',
        text: FOOTNOTE
    },
    expansion: {
        symbol: '**',
        text: EXPANSION_FOOTNOTE
    }
};
