import React, {FormEventHandler, useEffect} from "react";
import {ApolloProvider} from "@apollo/client";
import {HelmetProvider} from "react-helmet-async";
import styled, {ThemeProvider} from "styled-components";
import ScrollToTop from "react-router-scroll-top";

import {apolloClient} from "./config";
import theme from "./styles";
import Routes from "./Routes";

function App() {
    const [loggedIn, setLoggedIn] = React.useState(!!window.sessionStorage.getItem('betaLoggedIn'));

    useEffect(() => {
        if (loggedIn)
            window.sessionStorage.setItem('betaLoggedIn', 'true');
    }, [loggedIn])

    if (!loggedIn) {
        return (
            <BetaLogin setLoggedIn={setLoggedIn}/>
        );
    }

    return (
        <ApolloProvider client={apolloClient}>
            <HelmetProvider>
                <ThemeProvider theme={theme}>
                    <ScrollToTop>
                        <Routes/>
                    </ScrollToTop>
                </ThemeProvider>
            </HelmetProvider>
        </ApolloProvider>
    );
}

export default App;

const BlankScreen = styled.div`
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
  form {
    display: flex;
  }
  
  input, button {
    font-size: 32px;
  }
  
  input {
    width: 6em;
  }
`;

function BetaLogin({setLoggedIn}:{setLoggedIn:(loggedIn:boolean)=>void}) {
    const login: FormEventHandler = e => {
        e.preventDefault();

        const password = document.getElementById('beta-password') as HTMLInputElement;
        if (password.value === 'n3ws1t3')
            setLoggedIn(true);
    }

    return (
        <BlankScreen>
            <form onSubmit={login}>
                <input placeholder="password" type="password" id="beta-password" required/>
                <button type="submit">Login</button>
            </form>
        </BlankScreen>
    )
}